<template>
  <Layout>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'home' }">
            Home
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Editing {{ device.name }}
        </li>
      </ol>
    </nav>
    <div
      :class="['my-3 my-md-5 dropzone-area', isDragging ? 'dragging' : '']"
      @dragenter.prevent="isDragging = true"
      @dragend.prevent="isDragging = false"
      @dragleave.prevent="isDragging = false"
      @drop.prevent="addFile"
    >
      <!--   -->
      <div class="container-fluid">
        <div :class="['dimmer', isSaving ? 'active' : '']">
          <div class="loader"></div>
          <div class="dimmer-content">
            <b-form @submit="onSubmit">
              <div class="row">
                <!-- Settings -->
                <div class="col-12 col-md-4 col-lg-4 col-xl-3 mb-md-4">
                  <b-alert
                    v-model="showDismissibleAlert"
                    fade
                    variant="danger"
                    dismissible
                  >
                    It appears you are using a mobile device. Editing a
                    KegBit&trade; Tap layout on a mobile device is not suggested
                    at this time.
                  </b-alert>
                  <div class="card">
                    <div class="card-body p-sm-1 p-lg-4">
                      <h3 class="card-title mb-2">
                        <i class="fad fa-tools"></i> KegBit&trade; Tap Settings
                      </h3>

                      <div class="text-center mb-4 d-none d-md-block">
                        <b-img
                          :src="previewImage"
                          alt="KegBit&trade; Tap Preview"
                          :width="width"
                          :height="height"
                          fluid
                          thumbnail
                        ></b-img>
                      </div>

                      <b-form-group
                        label="Nickname"
                        class="mb-4"
                        label-size="sm"
                      >
                        <b-form-input
                          v-model="deviceEdit.name"
                          maxlength="12"
                          placeholder="Tap One"
                          trim
                          required
                        ></b-form-input>
                      </b-form-group>
                      <div v-if="isDesktop" class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-sm-6 col-md-8 pr-md-0 ">
                              <div class="form-group">
                                <label class="form-label">Font Name</label>
                                <select
                                  v-model="objFont"
                                  class="form-control"
                                  :disabled="!objFont"
                                >
                                  <option
                                    v-for="font in fontFamilies"
                                    :key="font.name"
                                  >
                                    {{ font.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div class="col-sm-6 col-md-4 pl-md--1">
                              <div class="form-group">
                                <label class="form-label">Size</label>
                                <select
                                  v-model="objFontSize"
                                  class="form-control"
                                  :disabled="!objFontSize"
                                >
                                  <option v-for="size in fontSizes" :key="size">
                                    {{ size }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- Color Stuff -->
                        <!-- Font Color -->
                        <div class="col-12">
                          <div class="row">
                            <div class="col text-center">
                              <fieldset class="form-group">
                                <legend
                                  class="col-form-label pt-0 col-form-label-sm"
                                >
                                  Text Color
                                </legend>
                                <pickr
                                  :value="fillColor"
                                  theme="nano"
                                  @save="setColor('fill', $event)"
                                ></pickr>
                              </fieldset>
                            </div>

                            <div class="col text-center">
                              <fieldset class="form-group">
                                <legend
                                  class="col-form-label pt-0 col-form-label-sm"
                                >
                                  Stroke
                                </legend>
                                <pickr
                                  :value="strokeColor"
                                  theme="nano"
                                  @save="setColor('stroke', $event)"
                                ></pickr>
                              </fieldset>
                            </div>

                            <div class="col text-center">
                              <fieldset class="form-group">
                                <legend
                                  class="col-form-label pt-0 col-form-label-sm"
                                >
                                  Background
                                </legend>
                                <pickr
                                  :value="backgroundColor"
                                  theme="nano"
                                  @save="setColor('backgroundColor', $event)"
                                ></pickr>
                              </fieldset>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer p-3">
                      <div class="d-flex mb-2 mb-md-0">
                        <div v-show="!isDemo" class="btn-list">
                          <b-button
                            variant="primary"
                            type="submit"
                            :disabed="isSaving"
                          >
                            Save Changes
                          </b-button>
                          <b-button
                            :to="{ name: 'home' }"
                            variant="link"
                            :disabed="isSaving"
                          >
                            Cancel
                          </b-button>
                        </div>
                        <div v-show="isDemo" class="btn-list">
                          <b-button
                            :to="{ name: 'home' }"
                            variant="link"
                            :disabed="isSaving"
                          >
                            Return to Dashboard
                          </b-button>
                        </div>
                        <div class="ml-auto d-block d-md-none">
                          <a
                            href="https://forms.gle/E4oKBqVpP1vFsvSi7"
                            target="_blank"
                            class="btn btn-link"
                          >
                            <i
                              class="fad fa-lightbulb-exclamation"
                              style="--fa-secondary-color: orange;"
                            ></i>
                            Feedback
                          </a>
                        </div>
                      </div>
                      <div class="text-center d-none d-md-block d-lg-none">
                        <a
                          href="https://forms.gle/E4oKBqVpP1vFsvSi7"
                          target="_blank"
                          class="btn btn-link"
                        >
                          <i
                            class="fad fa-lightbulb-exclamation"
                            style="--fa-secondary-color: orange;"
                          ></i>
                          Feedback
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- KegBit Tap Editor -->
                <div class="col-12 col-md-7 col-lg-8 mb-4">
                  <div class="card">
                    <div class="card-body p-sm-1 p-lg-4">
                      <h3 class="card-title mb-2">
                        <i class="fad fa-ruler-triangle"></i> Screen Layout
                      </h3>
                      <b-button-toolbar
                        aria-label="Toolbar with button groups"
                        class="mb-2"
                        key-nav
                      >
                        <b-dropdown
                          variant="link"
                          text="Insert Item"
                          toggle-class="text-secondary text-decoration-none"
                        >
                          <b-dropdown-item-button
                            title="Add Text"
                            @click="addText"
                          >
                            <i class="fad fa-text fa-fw"></i> Text
                          </b-dropdown-item-button>
                          <b-dropdown-item-button
                            title="Add Horizontal Line"
                            @click="addLine('h')"
                          >
                            <i class="fad fa-horizontal-rule fa-fw"></i>
                            Horizontal Line
                          </b-dropdown-item-button>
                          <b-dropdown-item-button
                            title="Add Vertical Line"
                            @click="addLine('v')"
                          >
                            <i
                              class="fad fa-horizontal-rule fa-rotate-90 fa-fw"
                            ></i>
                            Vertical Line
                          </b-dropdown-item-button>
                          <b-dropdown-item-button
                            title="Add Circle"
                            @click="addCircle"
                          >
                            <i class="fad fa-circle fa-fw"></i> Circle
                          </b-dropdown-item-button>
                          <b-dropdown-item-button
                            title="Add Square"
                            @click="addSquare"
                          >
                            <i class="fad fa-square fa-fw"></i> Square
                          </b-dropdown-item-button>
                          <b-dropdown-item-button
                            title="Add Image"
                            @click="$refs.imageFile.click()"
                          >
                            <i class="fad fa-image fa-fw"></i> Image
                            <input
                              ref="imageFile"
                              style="display: none"
                              type="file"
                              accept="image/jpg,image/png,image/gif"
                              @change="addImage"
                            />
                          </b-dropdown-item-button>
                          <b-dropdown-item-button
                            title="Add QR Code"
                            @click="addQrCode"
                          >
                            <i class="fad fa-qrcode fa-fw"></i> QR Code
                          </b-dropdown-item-button>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item-button
                            title="Add Untappd Beer"
                            @click="addUntappd"
                          >
                            <i class="fab fa-untappd fa-fw mr-2"></i>
                            Untappd Beer
                          </b-dropdown-item-button>
                        </b-dropdown>

                        <b-dropdown
                          :disabled="!hasSelection"
                          class="ml-1"
                          text="Options"
                          variant="link"
                          toggle-class="text-secondary text-decoration-none"
                        >
                          <b-dropdown-item-button @click="align('left')">
                            <i class="fad fa-align-left fa-fw"></i> Align Left
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="align('center')">
                            <i class="fad fa-align-center fa-fw"></i> Align
                            Center
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="align('right')">
                            <i class="fad fa-align-right fa-fw"></i> Align Right
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="align('justify')">
                            <i class="fad fa-align-justify fa-fw"></i> Justify
                            All
                          </b-dropdown-item-button>

                          <b-dropdown-divider></b-dropdown-divider>

                          <b-dropdown-item-button @click="sendTo('back')">
                            <i class="fad fa-send-backward fa-fw"></i>
                            Send Backwards
                          </b-dropdown-item-button>
                          <b-dropdown-item-button @click="sendTo('front')">
                            <i class="fad fa-bring-forward fa-fw"></i>
                            Bring Forwards
                          </b-dropdown-item-button>

                          <b-dropdown-divider></b-dropdown-divider>

                          <b-dropdown-item-button @click="groupAction('group')">
                            <i class="fad fa-object-group fa-fw"></i> Group
                          </b-dropdown-item-button>
                          <b-dropdown-item-button
                            @click="groupAction('ungroup')"
                          >
                            <i class="fad fa-object-ungroup fa-fw"></i>
                            Ungroup
                          </b-dropdown-item-button>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item-button @click="setObjId">
                            <i class="fad fa-hat-cowboy-side fa-fw"></i> Set
                            Object ID
                          </b-dropdown-item-button>
                        </b-dropdown>

                        <b-button
                          :disabled="!hasSelection"
                          title="Remove the selected object(s)"
                          variant="link"
                          class="ml-1 text-secondary  ext-decoration-none"
                          @click="removeObjects"
                        >
                          <i class="fad fa-trash"></i>
                        </b-button>

                        <b-button
                          id="preview_tap"
                          title="Preview"
                          variant="link"
                          class="d-block d-md-none text-secondary text-decoration-none"
                        >
                          <i class="fad fa-eye"></i> Preview
                        </b-button>
                        <b-popover
                          target="preview_tap"
                          placement="bottom"
                          triggers="click"
                        >
                          <b-img
                            :src="previewImage"
                            alt="KegBit&trade; Tap Preview"
                            :width="width"
                            :height="height"
                            fluid
                            thumbnail
                          ></b-img>
                        </b-popover>
                        <b-dropdown
                          class="ml-auto"
                          right
                          variant="link"
                          no-caret
                          toggle-class="text-secondary text-decoration-none"
                        >
                          <template v-slot:button-content>
                            <i class="fad fa-ellipsis-v-alt"></i>
                            <span class="sr-only">Search</span>
                          </template>
                          <b-dropdown-item @click="clearAll">
                            Reset Screen
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-button-toolbar>
                      <fabric
                        ref="canvas"
                        :height="height"
                        :width="width"
                        :zoom="zoom"
                        @keydown="keypressHandler"
                        @object:added="handleAfterRender"
                        @object:modified="handleAfterRender"
                        @object:removed="handleAfterRender"
                        @selection:cleared="handleSelection('cleared', $event)"
                        @selection:created="handleSelection('created', $event)"
                        @selection:updated="handleSelection('updated', $event)"
                      ></fabric>
                    </div>
                  </div>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <UntappdDialog @selected="untappdSelected"></UntappdDialog>
  </Layout>
</template>

<script>
import Layout from '@layouts/Main';
import Fabric from '@components/fabric/fabric.vue';
import Pickr from '@components/Pickr.vue';
import UntappdDialog from '@components/UntappdDialog.vue';

import { desktop as isDesktop } from 'is_js';

import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import has from 'lodash/has';

import { getImageFromUrl, getBase64 } from '@utils/imageHelpers.js';

import QRCode from 'qrcode';
import Dither from 'canvas-dither';
import Jimp from 'jimp';
import { demoLayout } from '@utils/demo_layout';

const fontFamilies = [
  { name: 'Arial' },
  { name: 'Comfortaa' },
  { name: 'Fira Mono' },
  { name: 'Fira Sans' },
  { name: 'Roboto' },
  { name: 'Sniglet' },
  { name: 'Source Sans Pro' },
  { name: 'Ubuntu' },
  { name: 'Ubuntu Condensed' },
  { name: 'Ubuntu Mono' },
];

export default {
  name: 'EditKegBitTap',
  components: {
    Layout,
    Fabric,
    Pickr,
    UntappdDialog,
  },
  props: { device: Object, isDemo: Boolean },
  data() {
    return {
      isDragging: false,
      isSaving: false,
      layout: null,
      showDismissibleAlert: !isDesktop(),
      zoom: 1.5,
      deviceEdit: { name: null },
      restoreDevice: null,
      isFirstUse: true,
      isEditing: false,
      areFontsLoaded: false,
      dither: 80,
      api_url: process.env.VUE_APP_API_URL,
      width: 128,
      height: 296,
      updatedImage: null,
      activeFont: null,
      fontSizes: [6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 24, 36],
      fontFamily: 'Arial',
      fontSize: 8,
      fontFamilies: fontFamilies,
      selectedObject: null,
      hasSelection: false,

      imgPreviewWorker: null,
      handleAfterRender: debounce(this.handleAfterRenderNonDebounced, 257),
    };
  },
  computed: {
    isDesktop() {
      return isDesktop();
    },
    previewImage() {
      if (this.updatedImage) {
        return this.updatedImage;
      }
      return this.device?.dithered?.png;
    },
    objFont: {
      get: function() {
        if (
          this.selectedObject &&
          ['textbox', 'text'].includes(this.selectedObject.type.toLowerCase())
        ) {
          return this.selectedObject?.fontFamily || 'Arial';
        }
        return null;
      },
      set: function(newValue) {
        if (this.selectedObject) {
          this.setFont(newValue);
        }
      },
    },
    objFontSize: {
      get: function() {
        if (
          this.selectedObject &&
          ['textbox', 'text'].includes(this.selectedObject.type.toLowerCase())
        ) {
          return this.selectedObject?.fontSize || '9';
        }
        return null;
      },
      set: function(newValue) {
        if (this.selectedObject) {
          this.setFontSize(newValue);
        }
      },
    },
    fillColor: {
      get: function() {
        if (!this.selectedObject) {
          return null;
        }
        const objs = this.$refs.canvas.getActiveObjects();
        return { color: objs[0].get('fill') };
      },
      set: function(newValue) {
        this.setColor('fill', newValue);
      },
    },
    strokeColor: {
      get: function() {
        if (!this.selectedObject) {
          return null;
        }
        const objs = this.$refs.canvas.getActiveObjects();
        return { color: objs[0].get('stroke') };
      },
      set: function(newValue) {
        this.setColor('stroke', newValue);
      },
    },
    backgroundColor: {
      get: function() {
        if (!this.selectedObject) {
          return null;
        }
        const objs = this.$refs.canvas.getActiveObjects();
        return { color: objs[0].get('backgroundColor') };
      },
      set: function(newValue) {
        this.setColor('backgroundColor', newValue);
      },
    },
  },
  created() {
    this.refreshCanvas = debounce(this.refreshCanvasDebounced, 250);

    if (this.isDesktop) {
      this.zoom = 2;
    }

    this.width = 176;
    this.height = 264;
  },
  async mounted() {
    window.addEventListener('keyup', this.keypressHandler);

    this.deviceEdit = Object.assign({}, this.device);

    const layout = await this.$store.dispatch('devices/getCurrentLayout', {
      deviceId: this.device.id,
    });
    // console.log('demoLayout', demoLayout);
    if (isNil(layout)) {
      this.layout = demoLayout;
    } else {
      this.layout = layout;
    }

    this.initCanvas();

    this.$nextTick(() => {
      this.refreshCanvas();
    });
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keypressHandler);
  },
  methods: {
    keypressHandler(e) {
      if (
        (e.code === 'Delete' || e.code === 'Backspace') &&
        e.ctrlKey === true
      ) {
        const objs = this.$refs.canvas.getActiveObjects();

        objs.forEach((obj) => {
          this.$refs.canvas.remove(obj);
        });

        this.$refs.canvas.renderAll();
      }
    },

    initCanvas() {
      const padding = 2;

      const layout = JSON.parse(this.layout);

      if (layout && layout.objects.length > 0) {
        this.isFirstUse = false;
        this.$refs.canvas.loadFromJSON(layout);
      } else {
        this.$refs.canvas.loadSvg('/static/svg/kegbit-pint-color.svg', {
          id: 'beer_logo',
          top: -6,
          left: 48,
          scaleX: 0.75,
          scaleY: 0.75,
        });

        var beerName = this.$refs.canvas.createTextbox('Your Beer Name', {
          id: 'beer_name',
          left: 2,
          top: 120,
          width: Math.round(this.width - padding * 2),
          fontSize: 22,
          fontFamily: 'Ubuntu Condensed',
        });

        this.$refs.canvas.createTextbox('Beer Style', {
          id: 'beer_style',
          left: padding,
          top: beerName.top + beerName.height + 5,
          width: Math.round(this.width / 2),
          fontSize: 18,
          fontFamily: 'Ubuntu Condensed',
        });

        this.$refs.canvas.createTextbox('IBU: XX', {
          id: 'beer_ibu',
          left: padding,
          top: 221,
          width: Math.round(this.width / 2),
          fontSize: 24,
          fontFamily: 'Ubuntu Condensed',
        });

        this.$refs.canvas.createTextbox(`ABV: X.X%`, {
          id: 'beer_abv',
          left: 78,
          top: 221,
          width: 100,
          fontSize: 24,
          fontFamily: 'Ubuntu Condensed',
        });
      }

      this.$refs.canvas.renderAll();

      this.$refs.canvas.setZoom(this.zoom);
    },

    refreshCanvasDebounced() {
      this.$refs.canvas.renderAll();
    },

    resetDevice() {
      this.initCanvas(this.device);
    },
    clearAll() {
      this.$refs.canvas.clear();
    },
    searchBeer: debounce(function() {
      this.untappdSearching = true;

      return this.$store
        .dispatch('untappd/untappdSearch', {
          search: this.untappdSearchString,
        })
        .then((response) => {
          this.untappdSearchResults = response;
          this.untappdTableOptions.currentPage = 1;

          if (this.untappdSearchResults) {
            this.untappdTableOptions.totalRows = this.untappdSearchResults.length;
          } else {
            this.untappdTableOptions.totalRows = 0;
          }

          this.untappdSearching = false;
        });
    }, 500),

    handleAfterRenderNonDebounced() {
      // console.log('handleAfterRender');
      if (isNil(this.$refs.canvas)) {
        return;
      }

      const pngBuffer = this.$refs.canvas.toPngBuffer();

      if (isNil(pngBuffer)) {
        return;
      }

      Jimp.read(pngBuffer).then((img) => {
        img.bitmap = Dither.atkinson(
          img.resize(176, 264, Jimp.RESIZE_NEAREST_NEIGHBOR).bitmap
        );

        img.getBase64(Jimp.MIME_PNG, (err, src) => {
          if (err) {
            console.error(err);
          } else {
            this.updatedImage = src;
          }
        });
      });

      // this.updatePreviewWorker
      //   .postMessage('updatePreview', [pngBuffer])
      //   .then((result) => {
      // });
    },

    removeObjects() {
      this.$refs.canvas.getActiveObjects().forEach((x) => {
        this.$refs.canvas.remove(x);
      });

      // this.$refs.canvas.removeCurrentObj();
      this.handleAfterRender();
    },

    // FabricJS Commands
    addText() {
      this.$refs.canvas.createTextbox('Text', {
        fontFamily: 'Arial',
        fontSize: 12,
      });
      this.handleAfterRender();
    },

    addLine(dir) {
      if (dir === 'h') {
        this.$refs.canvas.createLine({ x: 10, y: 150, x1: 125, y1: 150 });
      } else {
        this.$refs.canvas.createLine({ x: 10, y: 150, x1: 10, y1: 250 });
      }
      this.handleAfterRender();
    },

    addCircle() {
      this.$refs.canvas.createCircle();
      this.handleAfterRender();
    },

    addSquare() {
      this.$refs.canvas.createRect();
      this.handleAfterRender();
    },

    async addImage() {
      const file = this.$refs.imageFile.files[0];

      const base64 = await getBase64(file);

      this.$refs.canvas.createImageByUrl(base64);

      this.handleAfterRender();
    },

    sendTo(value) {
      if (value === 'back') {
        this.$refs.canvas.toBottomLayer();
      } else if (value === 'front') {
        this.$refs.canvas.toTopLayer();
      } else {
        this.$refs.canvas.toBottomLayer();
      }
    },

    setFont(fontFamily) {
      this.$refs.canvas.getActiveObjects().forEach((x) => {
        x.set({ fontFamily: fontFamily });
      });

      this.$refs.canvas.renderAll();
    },

    setFontSize(fontSize) {
      this.$refs.canvas.getActiveObjects().forEach((x) => {
        x.set({ 'fontSize ': fontSize });
      });
      this.$refs.canvas.renderAll();
    },

    setColor(prop, color) {
      this.$refs.canvas.getActiveObjects().forEach((x) => {
        x.set(prop, color);
      });

      this.$refs.canvas.renderAll();
    },

    setBackgroundColor(color) {
      this.setColor('backgroundColor', color);
    },

    setStrokeColor(color) {
      this.setColor('stroke', color);
    },

    setFillColor(color) {
      this.setColor('fill', color);
    },

    objIs(objType) {
      return this.selectedObject?.type === objType.toLowerCase();
    },

    objHas(propName) {
      return has(this.selectedObject, propName);
    },

    align(dir) {
      var group = this.$refs.canvas.getActiveObjects();
      if (group.length === 0) {
        return;
      }

      if (group.length === 1) {
        group[0].textAlign = dir;
      }

      this.$refs.canvas.renderAll();
    },

    handleSelection(action, event) {
      if (action === 'cleared') {
        this.isEditing = false;
      } else {
        this.isEditing = true;
      }
      this.showObjectProperties();

      this.hasSelection = this.$refs.canvas.getActiveObjects().length > 0;
    },

    updateCanvas: debounce(function() {
      // this.$refs.canvas.setBackgroundColor(this.canvas.backgroundColor);
      this.refreshCanvas();
    }, 250),

    setActiveItemProps: debounce(function() {
      if (!this.isEditing) {
        return;
      }

      const object = this.$refs.canvas.getActiveObjects();

      if (!object && object.length > 0) {
        return;
      }

      const props = [
        'fill',
        'stroke',
        'backgroundColor',
        'fontFamily',
        'fontSize',
      ];

      object.forEach((obj) => {
        props.forEach((prop) => {
          if (
            typeof obj[prop] !== 'undefined' &&
            obj[prop] !== this.selectedObject[prop]
          ) {
            obj.set(prop, this.selectedObject[prop]);
          }
        });
      });

      this.handleAfterRender();

      this.refreshCanvas();
    }, 250),

    showObjectProperties() {
      if (!this.isEditing) {
        this.selectedObject = null;
        return;
      }

      const object = this.$refs.canvas.getActiveObjects();

      if (!object && object.length === 0) {
        this.selectedObject = null;
      } else {
        const {
          type,
          backgroundColor,
          fill,
          stroke,
          fontFamily,
          fontSize,
        } = object[0];

        this.selectedObject = {
          type,
          stroke,
          fill,
          backgroundColor,
          fontFamily,
          fontSize,
        };
      }
    },
    async addQrCode() {
      var text = window.prompt('QR Data', 'beer!');

      if (text.length > 0) {
        const qrCodeData = await await QRCode.toDataURL(text);

        this.$refs.canvas.createImageByUrl(qrCodeData);
      }
    },

    // Untappd Items
    addUntappd() {
      this.$root.$emit('untappd:show');
    },
    async untappdSelected(beerInfo) {
      const padding = 2;
      let logo = beerInfo.beer_label_hd;

      if (isNil(logo) || logo.length === 0) {
        logo = beerInfo.beer_label;
      }

      const breweryLabel = await getImageFromUrl(logo);

      let beerLogo = this.$refs.canvas.getObject('beer_logo');

      if (beerLogo && beerLogo.type === 'image') {
        this.$refs.canvas.updateImageSrc('beer_logo', breweryLabel);
      } else {
        await this.$refs.canvas.createImageByUrl(breweryLabel, {
          id: 'beer_logo',
          left: 1,
          top: 1,
        });
        beerLogo = this.$refs.canvas.getObject('beer_logo');
      }

      let beerName = this.$refs.canvas.getObject('beer_name');
      console.log('beerLogo', beerLogo);
      if (beerName) {
        beerName.set({
          text: beerInfo.beer_name || 'Your Beer Name',
          top: beerLogo.top + beerLogo.height,
        });
      } else {
        beerName = this.$refs.canvas.createTextbox(
          beerInfo.beer_name || 'Your Beer Name',
          {
            id: 'beer_name',
            left: padding,
            top: beerLogo.top + beerLogo.height * beerLogo.scaleX,
            width: this.$refs.canvas.width - padding * 2,
            fontSize: 20,
            fontFamily: 'Ubuntu Condensed',
          }
        );
      }

      let beerStyle = this.$refs.canvas.getObject('beer_style');
      if (beerStyle) {
        beerStyle.set({ text: beerInfo.beer_style || 'Beer Style' });
      } else {
        beerStyle = this.$refs.canvas.createTextbox(beerInfo.beer_style, {
          id: 'beer_style',
          left: padding,
          top: beerName.top + beerName.height,
          width: this.$refs.canvas.width - padding * 2,
          fontSize: 14,
          fontFamily: 'Ubuntu Condensed',
        });
      }

      let beerIbu = this.$refs.canvas.getObject('beer_ibu');
      if (beerIbu) {
        beerIbu.set({ text: `IBU: ${beerInfo.beer_ibu || 'XX'}` });
      } else {
        beerIbu = this.$refs.canvas.createTextbox('IBU: ' + beerInfo.beer_ibu, {
          id: 'beer_ibu',
          left: padding,
          top: beerStyle.top + beerStyle.height,
          width: this.$refs.canvas.width - padding * 2,
          fontSize: 16,
          fontFamily: 'Ubuntu Condensed',
        });
      }

      const beerAbv = this.$refs.canvas.getObject('beer_abv');
      if (beerAbv) {
        beerAbv.set({ text: `ABV: ${beerInfo.beer_abv || 'XX'}%` });
      } else {
        this.$refs.canvas.createTextbox(`ABV: ${beerInfo.beer_abv}%`, {
          id: 'beer_abv',
          left: this.$refs.canvas.width / 2 + 2,
          top: beerStyle.top + beerStyle.height,
          width: this.$refs.canvas.width - padding * 2,
          fontSize: 16,
          fontFamily: 'Ubuntu Condensed',
        });
      }
      this.refreshCanvas();
    },

    resetUntappd() {
      this.untappdSearching = false;
      this.untappdSelectedItem = null;
      this.untappdSearchString = '';
      this.untappdSearchResults = [];
      this.untappdTableOptions = Object.assign(this.untappdTableOptions, {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15],
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
      });
    },

    async saveChanges() {
      this.isSaving = true;

      const canvas = this.$refs.canvas;

      const { id } = this.device;
      const { name } = this.deviceEdit;

      await this.$store.dispatch('devices/updateDevice', {
        id,
        name,
        image: canvas.toPngBuffer(),
        layout: canvas.toJsonString(),
      });

      this.$router.push({ name: 'home' }, () => {
        this.$eventbus.$emit('device:saved', `'${name}' successfully saved!`);
      });
    },

    onSubmit(evt) {
      evt.preventDefault();
      this.saveChanges();
    },

    setObjId() {
      const selectedObj = this.$refs.canvas.getActiveObjects()[0];

      const id = prompt('Obejct ID', selectedObj.id);
      if (id) {
        selectedObj.set('id', id);
      }
    },
    async addFile(e) {
      e = e || window.event;
      if (e.preventDefault) {
        e.preventDefault();
      }
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach(async (f) => {
        if (['image/jpg', 'image/png', 'image/gif'].includes(f.type)) {
          const base64 = await getBase64(f);
          this.$refs.canvas.createImageByUrl(base64);
        }
      });
    },
  },
};
</script>

<style>
.dropzone-area {
}
,
.dropzone-area.dragging {
  background-color: #c0c0c0;
}
.beer-logo {
  min-height: 128px;
  min-width: 128px;
}

.upper-canvas {
  border: 1px solid rgba(0, 40, 100, 0.12);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.input-icon-addon.clickable {
  pointer-events: auto;
  cursor: pointer !important;
}
</style>
