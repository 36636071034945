export const demoLayout = `{
    "version": "3.2.0",
    "objects": [{
        "type": "textbox",
        "version": "3.2.0",
        "originX": "left",
        "originY": "top",
        "left": 2,
        "top": 120,
        "width": 172,
        "height": 24.86,
        "fill": "#000000",
        "stroke": null,
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeMiterLimit": 4,
        "scaleX": 1,
        "scaleY": 1,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "clipTo": null,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "transformMatrix": null,
        "skewX": 0,
        "skewY": 0,
        "text": "Your Beer Name",
        "fontSize": 22,
        "fontWeight": "normal",
        "fontFamily": "Ubuntu Condensed",
        "fontStyle": "normal",
        "lineHeight": 1.16,
        "underline": false,
        "overline": false,
        "linethrough": false,
        "textAlign": "left",
        "textBackgroundColor": "",
        "charSpacing": 0,
        "minWidth": 20,
        "splitByGrapheme": false,
        "id": "beer_name",
        "styles": {}
    }, {
        "type": "textbox",
        "version": "3.2.0",
        "originX": "left",
        "originY": "top",
        "left": 2,
        "top": 149.86,
        "width": 88,
        "height": 20.34,
        "fill": "#000000",
        "stroke": null,
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeMiterLimit": 4,
        "scaleX": 1,
        "scaleY": 1,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "clipTo": null,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "transformMatrix": null,
        "skewX": 0,
        "skewY": 0,
        "text": "Beer Style",
        "fontSize": 18,
        "fontWeight": "normal",
        "fontFamily": "Ubuntu Condensed",
        "fontStyle": "normal",
        "lineHeight": 1.16,
        "underline": false,
        "overline": false,
        "linethrough": false,
        "textAlign": "left",
        "textBackgroundColor": "",
        "charSpacing": 0,
        "minWidth": 20,
        "splitByGrapheme": false,
        "id": "beer_style",
        "styles": {}
    }, {
        "type": "textbox",
        "version": "3.2.0",
        "originX": "left",
        "originY": "top",
        "left": 2,
        "top": 221,
        "width": 88,
        "height": 27.12,
        "fill": "#000000",
        "stroke": null,
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeMiterLimit": 4,
        "scaleX": 1,
        "scaleY": 1,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "clipTo": null,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "transformMatrix": null,
        "skewX": 0,
        "skewY": 0,
        "text": "IBU: XX",
        "fontSize": 24,
        "fontWeight": "normal",
        "fontFamily": "Ubuntu Condensed",
        "fontStyle": "normal",
        "lineHeight": 1.16,
        "underline": false,
        "overline": false,
        "linethrough": false,
        "textAlign": "left",
        "textBackgroundColor": "",
        "charSpacing": 0,
        "minWidth": 20,
        "splitByGrapheme": false,
        "id": "beer_ibu",
        "styles": {}
    }, {
        "type": "textbox",
        "version": "3.2.0",
        "originX": "left",
        "originY": "top",
        "left": 78,
        "top": 221,
        "width": 100,
        "height": 27.12,
        "fill": "#000000",
        "stroke": null,
        "strokeWidth": 1,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeMiterLimit": 4,
        "scaleX": 1,
        "scaleY": 1,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "clipTo": null,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "transformMatrix": null,
        "skewX": 0,
        "skewY": 0,
        "text": "ABV: X.X%",
        "fontSize": 24,
        "fontWeight": "normal",
        "fontFamily": "Ubuntu Condensed",
        "fontStyle": "normal",
        "lineHeight": 1.16,
        "underline": false,
        "overline": false,
        "linethrough": false,
        "textAlign": "left",
        "textBackgroundColor": "",
        "charSpacing": 0,
        "minWidth": 20,
        "splitByGrapheme": false,
        "id": "beer_abv",
        "styles": {}
    }, {
        "type": "group",
        "version": "3.2.0",
        "originX": "left",
        "originY": "top",
        "left": 48,
        "top": -6,
        "width": 96.71,
        "height": 167.52,
        "fill": "rgb(0,0,0)",
        "stroke": null,
        "strokeWidth": 0,
        "strokeDashArray": null,
        "strokeLineCap": "butt",
        "strokeDashOffset": 0,
        "strokeLineJoin": "miter",
        "strokeMiterLimit": 4,
        "scaleX": 0.75,
        "scaleY": 0.75,
        "angle": 0,
        "flipX": false,
        "flipY": false,
        "opacity": 1,
        "shadow": null,
        "visible": true,
        "clipTo": null,
        "backgroundColor": "",
        "fillRule": "nonzero",
        "paintFirst": "fill",
        "globalCompositeOperation": "source-over",
        "transformMatrix": null,
        "skewX": 0,
        "skewY": 0,
        "id": "beer_logo",
        "objects": [{
            "type": "path",
            "version": "3.2.0",
            "originX": "left",
            "originY": "top",
            "left": -30.41,
            "top": -71.22,
            "width": 59.46,
            "height": 23.79,
            "fill": "rgb(0,0,0)",
            "stroke": null,
            "strokeWidth": 1,
            "strokeDashArray": null,
            "strokeLineCap": "butt",
            "strokeDashOffset": 0,
            "strokeLineJoin": "miter",
            "strokeMiterLimit": 4,
            "scaleX": 1,
            "scaleY": 1,
            "angle": 0,
            "flipX": false,
            "flipY": false,
            "opacity": 1,
            "shadow": null,
            "visible": true,
            "clipTo": null,
            "backgroundColor": "",
            "fillRule": "nonzero",
            "paintFirst": "fill",
            "globalCompositeOperation": "source-over",
            "transformMatrix": null,
            "skewX": 0,
            "skewY": 0,
            "path": [
                ["M", 18.44, 24.93],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"],
                ["M", 29.69, 30.88],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"],
                ["M", 48.16, 30.88],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"],
                ["M", 66.01, 30.88],
                ["h", -5.95],
                ["v", -5.95],
                ["h", 5.95],
                ["z"],
                ["M", 53.17, 13.04],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"],
                ["M", 71.95, 30.88],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"],
                ["M", 42.22, 18.99],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"]
            ]
        }, {
            "type": "path",
            "version": "3.2.0",
            "originX": "left",
            "originY": "top",
            "left": -24.48,
            "top": -29.6,
            "width": 53.52,
            "height": 11.9,
            "fill": "#e3a824",
            "stroke": null,
            "strokeWidth": 1,
            "strokeDashArray": null,
            "strokeLineCap": "butt",
            "strokeDashOffset": 0,
            "strokeLineJoin": "miter",
            "strokeMiterLimit": 4,
            "scaleX": 1,
            "scaleY": 1,
            "angle": 0,
            "flipX": false,
            "flipY": false,
            "opacity": 1,
            "shadow": null,
            "visible": true,
            "clipTo": null,
            "backgroundColor": "",
            "fillRule": "nonzero",
            "paintFirst": "fill",
            "globalCompositeOperation": "source-over",
            "transformMatrix": null,
            "skewX": 0,
            "skewY": 0,
            "path": [
                ["M", 54.11, 54.66],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"],
                ["M", 71.95, 60.61],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"],
                ["M", 30.33, 60.61],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"],
                ["M", 24.38, 54.66],
                ["h", 5.95],
                ["v", 5.95],
                ["h", -5.95],
                ["z"]
            ]
        }, {
            "type": "path",
            "version": "3.2.0",
            "originX": "left",
            "originY": "top",
            "left": -30.41,
            "top": -17.76,
            "width": 59.5,
            "height": 77.34,
            "fill": "#e3a824",
            "stroke": null,
            "strokeWidth": 1,
            "strokeDashArray": null,
            "strokeLineCap": "butt",
            "strokeDashOffset": 0,
            "strokeLineJoin": "miter",
            "strokeMiterLimit": 4,
            "scaleX": 1,
            "scaleY": 1,
            "angle": 0,
            "flipX": false,
            "flipY": false,
            "opacity": 1,
            "shadow": null,
            "visible": true,
            "clipTo": null,
            "backgroundColor": "",
            "fillRule": "nonzero",
            "paintFirst": "fill",
            "globalCompositeOperation": "source-over",
            "transformMatrix": null,
            "skewX": 0,
            "skewY": 0,
            "path": [
                ["M", 66, 66.55],
                ["H", 54.11],
                ["v", 6],
                ["h", -6],
                ["v", -6],
                ["H", 36.27],
                ["v", 6],
                ["h", 5.95],
                ["v", 5.94],
                ["h", -5.95],
                ["V", 72.5],
                ["h", -5.94],
                ["v", -6],
                ["H", 18.44],
                ["v", 29.78],
                ["h", 5.94],
                ["v", 23.78],
                ["h", 5.95],
                ["v", 23.78],
                ["H", 66],
                ["V", 126],
                ["h", 6],
                ["v", -23.77],
                ["h", 5.94],
                ["V", 72.5],
                ["H", 72],
                ["v", -6],
                ["z"],
                ["M", 54.11, 78.44],
                ["v", 6],
                ["h", -6],
                ["v", -6],
                ["z"],
                ["m", -11.89, 6],
                ["v", 5.94],
                ["h", -5.95],
                ["v", -5.99],
                ["z"],
                ["m", -17.84, -6],
                ["h", 5.95],
                ["v", 6],
                ["h", -5.95],
                ["z"],
                ["m", 41.62, 0],
                ["h", -6],
                ["V", 72.5],
                ["h", 6],
                ["z"]
            ]
        }, {
            "type": "path",
            "version": "3.2.0",
            "originX": "left",
            "originY": "top",
            "left": -36.36,
            "top": -41.49,
            "width": 71.35,
            "height": 107.02,
            "fill": "rgb(0,0,0)",
            "stroke": null,
            "strokeWidth": 1,
            "strokeDashArray": null,
            "strokeLineCap": "butt",
            "strokeDashOffset": 0,
            "strokeLineJoin": "miter",
            "strokeMiterLimit": 4,
            "scaleX": 1,
            "scaleY": 1,
            "angle": 0,
            "flipX": false,
            "flipY": false,
            "opacity": 1,
            "shadow": null,
            "visible": true,
            "clipTo": null,
            "backgroundColor": "",
            "fillRule": "nonzero",
            "paintFirst": "fill",
            "globalCompositeOperation": "source-over",
            "transformMatrix": null,
            "skewX": 0,
            "skewY": 0,
            "path": [
                ["M", 30.33, 48.71],
                ["h", 47.56],
                ["v", -5.94],
                ["H", 18.43],
                ["v", 5.94],
                ["h", 11.9],
                ["z"],
                ["M", 77.89, 48.71],
                ["v", 53.51],
                ["h", 5.95],
                ["V", 48.71],
                ["h", -5.95],
                ["z"],
                ["M", 71.95, 108.17],
                ["v", 17.84],
                ["h", 5.94],
                ["v", -23.79],
                ["h", -5.94],
                ["v", 5.95],
                ["z"],
                ["M", 66, 131.95],
                ["v", 11.89],
                ["H", 30.33],
                ["v", -23.78],
                ["h", -5.95],
                ["V", 96.28],
                ["h", -5.95],
                ["V", 48.71],
                ["h", -5.94],
                ["v", 53.51],
                ["h", 5.94],
                ["v", 23.79],
                ["h", 5.95],
                ["v", 23.78],
                ["H", 71.95],
                ["v", -23.78],
                ["H", 66],
                ["v", 5.94],
                ["z"]
            ]
        }]
    }],
    "background": "#ffffff"
}`;
