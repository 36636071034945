<template>
  <b-modal id="untappdDialog" footer-class="d-flex" size="lg" @ok="handleOk">
    <template slot="modal-title">
      <i class="fab fa-untappd fa-fw mr-2"></i>Untappd Beer Search
    </template>

    <template slot="modal-footer" slot-scope="{ ok, cancel }">
      <img src="@assets/images/pbu_40_grey.png" />
      <div class="d-flex ml-auto">
        <b-button
          :disabled="!untappdSelectedItem || untappdSearching"
          variant="primary"
          @click="ok()"
          >Select Beer</b-button
        >
        <b-button variant="link" @click="cancel()">Cancel</b-button>
      </div>
    </template>
    <template slot="default">
      <b-container fluid>
        <b-row>
          <b-col>
            <div class="form-group">
              <div class="row gutters-xs">
                <div class="col">
                  <div class="input-icon my-3 my-lg-0">
                    <input
                      ref="untappd_search"
                      v-model="untappdSearchString"
                      class="form-control"
                      placeholder="Pliny the Elder"
                      type="text"
                      @keyup="debouncedSearchBeer"
                    />

                    <div class="input-icon-addon">
                      <i
                        v-if="untappdSearching"
                        class="fas fa-spinner fa-pulse"
                      ></i>
                      <i v-else class="far fa-search"></i>
                    </div>
                  </div>
                  <small class="text-muted">
                    The best way to search is always "Brewery Name + Beer Name",
                    such as "Dogfish 60 Minute".
                  </small>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              :current-page="untappdTableOptions.currentPage"
              :fields="untappdTableOptions.fields"
              :filter="untappdTableOptions.filter"
              :hover="true"
              :items="untappdSearchResults"
              :per-page="untappdTableOptions.perPage"
              :sort-by.sync="untappdTableOptions.sortBy"
              :sort-desc.sync="untappdTableOptions.sortDesc"
              :sort-direction="untappdTableOptions.sortDirection"
              select-mode="single"
              selectable
              small
              show-empty
              striped
              @row-selected="untappdRowSelected"
            >
              <template v-slot:cell(beer_name)="data">
                <b-img
                  :src="data.item.beer_label"
                  alt="Image 1"
                  class="float-left"
                  height="45px"
                  thumbnail
                  width="45px"
                ></b-img>

                <div class="ml-2 d-inline-block">
                  <span class="text-default">{{ data.item.beer_name }}</span>
                  <small class="text-muted d-block mt-1">
                    {{ data.item.brewery_name }}
                  </small>
                </div>
              </template>
            </b-table>
            <b-pagination
              v-show="
                untappdTableOptions.totalRows > untappdTableOptions.perPage
              "
              v-model="untappdTableOptions.currentPage"
              :per-page="untappdTableOptions.perPage"
              :total-rows="untappdTableOptions.totalRows"
              align="right"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'UntappdDialog',
  data() {
    return {
      debouncedSearchBeer: debounce(this.searchBeer, 257),
      selectedObject: null,
      hasSelection: false,
      untappdSearching: false,
      untappdSelectedItem: null,
      untappdSearchString: '',
      untappdSearchResults: [],
      untappdTableOptions: {
        fields: [
          {
            key: 'beer_name',
            label: 'Beer Name',
            sortable: true,
            sortDirection: 'desc',
          },
          {
            key: 'beer_style',
            label: 'Style',
            sortable: true,
            class: 'text-left',
          },
          {
            key: 'beer_ibu',
            label: 'IBU',
            sortable: true,
            class: 'w-1 text-center',
          },
          {
            key: 'beer_abv',
            label: 'ABV',
            sortable: true,
            class: 'w-1 text-center',
          },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15],
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
      },
    };
  },
  created() {
    this.$root.$on('untappd:show', () => {
      // console.log('untappd:show');
      this.showDialog();
    });
    this.$root.$on('untappd:hide', () => {
      this.hideDialog();
    });
  },
  beforeDestroy: function() {
    this.$root.$off(['untappd:show', 'untappd:hide']);
  },
  methods: {
    showDialog() {
      this.$bvModal.show('untappdDialog');
    },
    hideDialog() {
      this.$bvModal.hide('untappdDialog');
    },
    async handleOk() {
      const beerInfo = await this.$store.dispatch('untappd/getBeerInfo', {
        beerId: this.untappdSelectedItem.beer_id,
      });

      this.$emit('selected', beerInfo);
    },
    untappdRowSelected(items) {
      this.untappdSelectedItem = items[0];
    },
    searchBeer() {
      this.untappdSearching = true;

      return this.$store
        .dispatch('untappd/untappdSearch', {
          search: this.untappdSearchString,
        })
        .then((response) => {
          this.untappdSearchResults = response;
          this.untappdTableOptions.currentPage = 1;

          if (this.untappdSearchResults) {
            this.untappdTableOptions.totalRows = this.untappdSearchResults.length;
          } else {
            this.untappdTableOptions.totalRows = 0;
          }

          this.untappdSearching = false;
        });
    },
  },
};
</script>
