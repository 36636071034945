<template>
  <div class="viewport" @keydown="$emit('keyup', $event)">
    <div class="wrapper">
      <canvas
        id="canvas"
        :height="height * zoom"
        :width="width * zoom"
        class="viewport--canvas"
      ></canvas>
    </div>
  </div>
</template>

<script>
/* global fabric */
import Utils from './utils';

export default {
  name: 'VueFabric',
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    options: {},
    zoom: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    canvas: null,
    currentObj: null,
  }),

  mounted() {
    this.canvas = new fabric.Canvas('canvas', {
      ...this.options,
    });

    const canvas = this.canvas;

    canvas.getContext().imageSmoothingEnabled = false;
    canvas.backgroundColor = '#ffffff';

    const events = [
      'object:modified',
      'object:rotated',
      'object:scaled',
      'object:moved',
      'object:skewed',
      'object:rotating',
      'object:scaling',
      'object:moving',
      'object:skewing',
      'before:transform',
      'before:selection:cleared',
      'selection:cleared',
      'selection:updated',
      'selection:created',
      'path:created',
      'mouse:down',
      'mouse:move',
      'mouse:up',
      'mouse:over',
      'mouse:out',
      'mouse:dblclick',
      'event:dragover',
      'event:dragenter',
      'event:dragleave',
      'event:drop',
    ];

    events.forEach((event) => {
      this.canvas.on(event, (options) => {
        this.$emit(event, options);
      });
    });
  },
  methods: {
    handleKeyUp(evt) {
      this.$emit('keyup', evt);
    },
    setZoom(zoom) {
      this.canvas.setZoom(zoom);
    },

    alignObjects(align) {
      const obj = this.canvas.getActiveObject();
      obj.textAlign = align;
      this.canvas.renderAll();
    },

    setCornerIcons({
      size = 20,
      borderColor = '#e4e4e4',
      cornerBackgroundColor = '#ffffff',
      cornerShape = 'rect',
    }) {
      // basic settings
      const that = this;

      fabric.Object.prototype.customiseCornerIcons(
        {
          settings: {
            borderColor: borderColor,
            cornerSize: size,
            cornerShape: cornerShape, // 'rect', 'circle'
            cornerBackgroundColor: cornerBackgroundColor,
          },
        },
        function() {
          that.canvas.renderAll();
        }
      );
    },

    drawDottedline(options) {
      options = Object.assign(
        {
          x: 0,
          y: 0,
          x1: 10,
          y1: 10,
          color: '#B2B2B2',
          drawWidth: 2,
          offset: 6,
          empty: 3,
        },
        options
      );
      const canvasObject = new fabric.Line(
        [options.x, options.y, options.x1, options.y1],
        {
          ...options,
          strokeDashArray: [options.offset, options.empty],
          stroke: options.color,
          strokeWidth: options.drawWidth,
        }
      );
      this.canvas.add(canvasObject);
      this.canvas.renderAll();
    },

    drawArrowLine(options) {
      options = Object.assign(
        {
          x: 0,
          y: 0,
          x1: 0,
          y1: 0,
          color: '#B2B2B2',
          drawWidth: 2,
          fillColor: 'rgba(255,255,255,0)',
          theta: 35,
          headlen: 35,
        },
        options
      );
      const canvasObject = new fabric.Path(
        this.drawArrowBase(
          options.x,
          options.y,
          options.x1,
          options.y1,
          options.theta,
          options.headlen
        ),
        {
          ...options,
          stroke: options.color,
          fill: options.fillColor,
          strokeWidth: options.drawWidth,
        }
      );
      this.canvas.add(canvasObject);
      this.canvas.renderAll();
    },

    freeDrawConfig(isDrawingMode, color = '#B2B2B2', drawWidth = 2) {
      this.canvas.isDrawingMode = isDrawingMode;
      this.canvas.freeDrawingBrush.color = color; // 设置自由绘颜色
      this.canvas.freeDrawingBrush.width = drawWidth;
    },
    remove(obj) {
      this.canvas.remove(obj);
      this.canvas.requestRenderAll();
    },
    removeCurrentObj() {
      const obj = this.canvas.getActiveObject();
      this.canvas.remove(obj);
      this.canvas.requestRenderAll();
    },

    removeAll() {
      const objs = this.canvas.getActiveObjects();

      objs.forEach((obj) => {
        this.canvas.remove(obj);
      });

      this.canvas.requestRenderAll();
    },

    getActiveObject() {
      return this.canvas.getActiveObject();
    },

    getActiveObjects() {
      return this.canvas.getActiveObjects();
    },

    getEditObj() {
      const obj = this.canvas.getActiveObject();
      this.removeCurrentObj();
      return obj;
    },

    setEditObj(obj) {
      this.canvas.add(obj);
      this.canvas.requestRenderAll();
    },

    setRotate(deg = 36) {
      const obj = this.canvas.getActiveObject();
      const angle = obj.angle;
      obj.rotate(angle + deg);
      this.canvas.renderAll();
    },

    discardActive() {
      this.canvas.discardActiveObject();
      this.canvas.discardActiveGroup();
      this.canvas.renderAll();
    },

    moveTo() {
      const obj = this.canvas.getActiveObject();
      // console.log(this.canvas.sendBackwards);
      this.canvas.sendBackwards(obj, true);
      this.canvas.discardActiveObject();
      this.canvas.discardActiveGroup();
    },

    createRect(options) {
      options = Object.assign(
        {
          width: 100,
          height: 100,
          fillColor: 'rgba(255, 255, 255, 0)',
          strokeColor: '#B0B0B0',
          stroke: '#aaf',
          strokeWidth: 1,
          strokeUniform: true,
          left: 10,
          top: 150,
        },
        options
      );

      const rect = new fabric.Rect({
        ...options,
        fill: options.fillColor, // 填充的颜色
      });

      this.canvas.add(rect);
      this.canvas.renderAll();
    },

    createCircle(options) {
      options = Object.assign(
        {
          left: 0,
          top: 0,
          radius: 30,
          fillColor: 'rgba(255, 255, 255, 0)',
          color: '#B2B2B2',
          drawWidth: 2,
        },
        options
      );
      const defaultOption = {
        ...options,
        fill: options.fillColor,
        strokeWidth: options.drawWidth,
        stroke: options.color,
      };
      const Circle = new fabric.Circle(defaultOption);
      this.canvas.add(Circle);
      this.canvas.renderAll();
    },

    createTriangle(options) {
      options = Object.assign(
        {
          x: 0,
          y: 0,
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 0,
          left: 100,
          top: 100,
          color: '#B2B2B2',
          drawWidth: 2,
          fillColor: 'rgba(255, 255, 255, 0)',
          id: 'triangle',
        },
        options
      );
      const path =
        'M ' +
        options.x +
        ' ' +
        options.y +
        ' L ' +
        options.x1 +
        ' ' +
        options.y1 +
        ' L ' +
        options.x2 +
        ' ' +
        options.y2 +
        ' z';
      const canvasObject = new fabric.Path(path, {
        ...options,
        stroke: options.color,
        strokeWidth: options.drawWidth,
        fill: options.fillColor,
      });
      this.canvas.add(canvasObject);
      this.canvas.renderAll();
    },

    createLine(options) {
      options = Object.assign(
        {
          x: 10,
          y: 150,
          x1: 125,
          y1: 150,
          fillColor: 'rgba(255, 255, 255, 0)',
          strokeColor: '#B0B0B0',
        },
        options
      );

      const line = new fabric.Line(
        [options.x, options.y, options.x1, options.y1],
        {
          ...options,
          fill: options.fillColor,
          stroke: options.strokeColor,
        }
      );

      this.canvas.add(line);
      this.canvas.requestRenderAll();
    },

    createEllipse(options) {
      options = Object.assign(
        {
          rx: 100,
          ry: 200,
          fillColor: 'rgba(255, 255, 255, 0)',
          angle: 90,
          strokeColor: '#B0B0B0',
          strokeWidth: 3,
          left: 50,
          top: 50,
        },
        options
      );

      const ellipse = new fabric.Ellipse({
        ...options,
        fill: options.fillColor,
        stroke: options.strokeColor,
      });

      this.canvas.add(ellipse);
      this.canvas.requestRenderAll();
    },

    createText(text = 'Text', options) {
      options = Object.assign({ left: 10, top: 100 }, options);

      const canvasObj = new fabric.Text(text, { ...options });

      this.canvas.add(canvasObj);
      this.canvas.requestRenderAll();
    },

    createTextbox(text = 'Text', options) {
      options = Object.assign(
        {},
        {
          fontSize: 14,
          fillColor: '#000000',
          registerObjectEvent: false,
          left: 10,
          top: 100,
        },
        options
      );
      // console.log('options', options);

      const canvasObj = new fabric.Textbox(text, options);

      this.canvas.add(canvasObj);

      if (options.registerObjectEvent) {
        Utils.registerObjectEvent(this, canvasObj);
      }

      this.canvas.requestRenderAll();

      return canvasObj;
    },

    lockObject(id) {
      const idx = this.canvas.getObjects().findIndex((o) => o.id === id);

      if (idx) {
        this.canvas.item(idx).selectable = false;
      }
    },
    getObject(id) {
      // console.group(id);
      const idx = this.canvas.getObjects().findIndex((o) => {
        // console.log('o.id', o.id, id);
        return o.id === id;
      });

      if (idx >= 0) {
        // console.log('idx', idx);
        // console.groupEnd();
        return this.canvas.item(idx);
      }
      // console.groupEnd();
      return null;
    },
    loadSvg(path, options) {
      const canvas = this.canvas;

      fabric.loadSVGFromURL(path, function(objects, svgOptions) {
        const obj = fabric.util.groupSVGElements(objects, svgOptions);
        canvas.add(obj.set(options));
      });
    },

    createImage(img, options) {
      const imgInstance = new fabric.Image(
        img,
        Object.assign(
          {
            left: 0,
            top: 0,
          },
          options
        )
      );

      this.canvas.add(imgInstance);

      this.canvas.requestRenderAll();
    },
    updateImageSrc(id, src) {
      const that = this;
      return new Promise(function(resolve, reject) {
        const obj = that.getObject(id);

        if (obj) {
          // console.log('updateImageSrc', obj);
          obj.setSrc(src, resolve);
        }
        resolve();
      });
    },
    createImageByUrl(url, options) {
      const that = this;

      return new Promise(function(resolve, reject) {
        const canvas = that.canvas;

        fabric.Image.fromURL(
          url,
          function(img) {
            const imgInstance = new fabric.Image(img); // eslint-disable-line no-unused-vars

            const maxWidth = that.width;
            let width = 0;
            let height = 0;

            if (img.width > img.height) {
              if (img.width > maxWidth) {
                width = maxWidth;
                height = (img.height / img.width) * width;
              } else {
                width = img.width;
                height = img.height;
              }
            } else {
              if (img.height > maxWidth) {
                height = maxWidth;
                width = (img.width / img.height) * height;
              } else {
                width = img.width;
                height = img.height;
              }
            }
            options = Object.assign(
              {
                left: 0,
                top: 0,
                scaleX: width / img.width,
                scaleY: height / img.height,
              },
              options
            );

            canvas.add(img.set(options));

            if (options && options.registerObjectEvent) {
              Utils.registerObjectEvent(that, img);
            }

            canvas.requestRenderAll();

            resolve();
          },
          { crossOrigin: 'Anonymous' }
        );
      });
    },

    toJson() {
      this.canvas.includeDefaultValues = false;
      const json = this.canvas.toJSON(['id']);
      return json;
    },

    toJsonString() {
      return JSON.stringify(this.toJson());
    },

    toJsonBuffer() {
      return Buffer.from(this.toJsonString(), 'utf8');
    },

    toDatalessJSON() {
      const json = this.canvas.toDatalessJSON(['id']);
      return json;
    },
    toImageData() {
      const ctx = this.canvas.getContext('webgl', {
        alpha: false,
        antialias: false,
      });
      return ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    },
    toPNG() {
      return this.canvas.toDataURL({
        format: 'png',
        enableRetinaScaling: true,
      });
    },
    toPngBuffer() {
      return Buffer.from(
        this.canvas
          .toDataURL({
            format: 'png',
          })
          .replace(/^data:image\/png;base64,/, ''),
        'base64'
      );
    },

    toDataUrl() {
      return this.canvas.toDataURL({
        format: 'png',
      });
    },

    loadFromJSON(json, cb) {
      const canvas = this.canvas;

      canvas.loadFromJSON(json, canvas.renderAll.bind(canvas), function(
        o,
        object
      ) {
        if (cb) {
          cb(o);
        }
      });
    },

    clear() {
      this.canvas.clear();
      this.canvas.backgroundColor = '#ffffff';
      this.canvas.requestRenderAll();
    },

    getObjects() {
      return this.canvas.getObjects();
    },

    renderAll() {
      this.canvas.renderAll(this.canvas);
    },

    renderTop() {
      this.canvas.renderTop();
    },
    getActiveGroup() {
      return this.canvas.getActiveGroup();
    },

    getBackgroundColor() {
      return this.canvas.backgroundColor;
    },
    setBackgroundColor(color) {
      const canvas = this.canvas;
      this.canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas));
      this.renderAll();
    },

    setBackgroundImage(
      imgUrl,
      opacity = 1,
      angle = 0,
      left = 0,
      top = 0,
      crossOrigin = null
    ) {
      const canvas = this.canvas;
      canvas.setBackgroundImage(imgUrl, canvas.renderAll.bind(canvas), {
        opacity: opacity,
        angle: angle,
        left: left,
        top: top,
        originX: 'left',
        originY: 'top',
        crossOrigin: crossOrigin,
      });
    },

    toSvg() {
      this.canvas.includeDefaultValues = false;

      return this.canvas.toSVG();
    },

    drawControls() {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      ctx.setLineDash([]);
      ctx.beginPath();
      ctx.ellipse(100, 100, 50, 75, (45 * Math.PI) / 180, 0, 2 * Math.PI); // 倾斜45°角
      ctx.stroke();
      ctx.setLineDash([5]);
      ctx.moveTo(0, 200);
      ctx.lineTo(200, 0);
      ctx.stroke();
      this.canvas.drawControls(ctx);
      // this.canvas.controlsAboveOverlay=true;
    },

    setContronVisibility(obj) {
      obj.setControlsVisibility({
        bl: true,
        br: true,
        mb: false,
        ml: true,
        mr: true,
        mt: false,
        mtr: true,
        tl: true,
        tr: true,
      });
    },
    toggleMirror({ flip = 'X' }) {
      const img = this.canvas.getActiveObject();
      if (img && img.type === 'image') {
        if (flip === 'X') {
          img.toggle('flipX');
        } else {
          img.toggle('flipY');
        }
        this.renderAll();
      }
    },

    toNextLayer() {
      const obj = this.canvas.getActiveObject();
      if (!obj) {
        return;
      }
      obj.sendBackwards(true);
      this.renderAll();
    },

    toBottomLayer() {
      const obj = this.canvas.getActiveObject();
      if (!obj) {
        return;
      }
      obj.sendToBack();
      this.renderAll();
    },

    toLastLayer() {
      const obj = this.canvas.getActiveObject();
      if (!obj) {
        return;
      }
      obj.bringForward(true);
      this.renderAll();
    },

    toTopLayer() {
      const obj = this.canvas.getActiveObject();
      if (!obj) {
        return;
      }
      obj.bringToFront();
      this.renderAll();
    },

    setFont(fontName) {
      const obj = this.canvas.getActiveObject();
      if (!obj) {
        return;
      }
      obj.fontFamily = fontName;
      this.renderAll();
    },
  },
};
// function calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
//   const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

//   return { width: srcWidth * ratio, height: srcHeight * ratio };
// }
</script>

<style lang="scss" scoped>
.viewport {
  height: 600px;
  left: 0px;
  top: 0;
  width: 100%;
  overflow: auto;
  background-color: #c0c0c0;
}

.viewport--canvas {
  border: 1px dashed gray;
}

.wrapper {
  height: 600px;
  width: 500px;
}
</style>
