<template>
  <div ref="pickr" class="pickr--wrapper"></div>
</template>

<script>
import Pickr from '@simonwep/pickr';

export default {
  name: 'VuePickr',
  props: {
    value: {
      type: [String, Object],
      default: '#FFFFFF00',
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    theme: {
      type: String,
      required: false,
      default: 'nano',
    },
  },
  data: () => ({ pickr: null }),
  watch: {
    value(val) {
      this.pickr.setColor(val?.color || '#FFFFFF00', true);
    },
  },
  mounted() {
    const defaultColor = this.value?.color || '#FFFFFF00';

    this.pickr = Pickr.create({
      el: this.$refs.pickr,
      default: defaultColor.toUpperCase(),
      theme: this.theme,
      defaultRepresentation: 'HEXA',
      closeOnScroll: true,
      comparison: false,
      swatches: [
        'rgba(233, 30, 99, 1)',
        'rgba(156, 39, 176, 1)',
        'rgba(103, 58, 183, 1)',
        'rgba(63, 81, 181, 1)',
        'rgba(33, 150, 243, 1)',
        'rgba(3, 169, 244, 1)',
        'rgba(0, 188, 212, 1)',
        'rgba(0, 150, 136, 1)',
        'rgba(76, 175, 80,1)',
        'rgba(139, 195, 74, 1)',
        'rgba(205, 220, 57, 1)',
        'rgba(255, 235, 59, 1)',
        'rgba(255, 193, 7, 1)',
        'rgba(0, 0, 0, 1)',
      ],
      components: {
        preview: true,
        opacity: true,
        hue: true,
        interaction: {
          input: true,
          clear: false,
          save: true,
          cancel: true,
        },
      },
      ...this.options,
    });

    this.pickr.on('save', (color, instance) => {
      const hexa = color.toHEXA().toString();
      this.$emit('save', hexa);
      this.pickr.hide();
    });

    this.pickr.on('cancel', (color, instance) => {
      this.pickr.hide();
    });
  },
  beforeDestroy() {
    this.pickr.destroy();
  },
};
</script>

<style>
.pcr-button {
  border: 1px solid rgba(0, 40, 100, 0.12) !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  border-radius: 3px !important;
}
</style>
